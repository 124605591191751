import { signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import type { IApplicationUI, ISwitchLanguage, ISwitchTheme } from '@archery-events/models/application-state.model';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { of, pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AuthService } from '../../services/http/auth.service';
import {
  DestroyRef,
  inject,
  Injector,
  isDevMode,
  PendingTasks,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  runInInjectionContext,
} from '@angular/core';
import type { ICsrf } from '@archery-events/models/auth.model';
import { TranslocoService } from '@jsverse/transloco';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';
import { CookieService } from '../../services/cookie.service';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { UserStore } from '../user/user.store';
import { IBreadcrumbStore } from '@owain/seo/models/breadcrumb.model';
import { LocalizeRouterService } from '@owain/ngx-transloco-router/localize-router.service';
import { getServerContext } from '../../utils/rendering';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, map, tap } from 'rxjs/operators';
import { SeoTagService } from '@owain/seo/services/seo-tag.service';
import { JsonLdService } from '../../services/seo/json-ld.service';
import { LocalStorageService } from '@owain/localstorage/local-storage.service';
import { immerPatchState } from '@owain/store-features/state/immer.state';

export const initialState: IApplicationUI = {
  language: null,
  theme: true,
  csrf: null,
  mainMenu: false,
  hideLayout: false,
  breadcrumbs: [],
  redirectUrl: null,
};

export const ApplicationUiStore = signalStore(
  { providedIn: 'root' },
  // withIndexedDB('application-ui-store-state', ['csrf']),
  withState(initialState),
  withMethods(store => {
    const authService: AuthService = inject(AuthService);
    const translocoService: TranslocoService = inject(TranslocoService);
    const ngDocument: Document = inject(DOCUMENT);
    const rendererFactory: RendererFactory2 = inject(RendererFactory2);
    const renderer: Renderer2 = rendererFactory.createRenderer(null, null);
    const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
    const meta: Meta = inject(Meta);
    const platformId: Object = inject(PLATFORM_ID);
    const pendingTasks: PendingTasks = inject(PendingTasks);
    const cookieService: CookieService = inject(CookieService);
    const localStorageService: LocalStorageService = inject(LocalStorageService);
    const injector: Injector = inject(Injector);

    return {
      toggleMenu(force: boolean): void {
        const taskCleanup = pendingTasks.add();

        if (force) {
          immerPatchState(store, state => {
            state.mainMenu = false;
          });
        } else {
          immerPatchState(store, state => {
            state.mainMenu = !store.mainMenu();
          });
        }

        taskCleanup();
      },

      setRedirectUrl(url: string | null | undefined): void {
        const taskCleanup = pendingTasks.add();

        immerPatchState(store, state => {
          state.redirectUrl = url;
        });

        taskCleanup();
      },

      generateCsrf: rxMethod<void>(
        pipe(
          switchMap(() => {
            const taskCleanup = pendingTasks.add();

            return authService.csrf().pipe(
              tapResponse({
                next: (res: ICsrf): void => {
                  immerPatchState(store, state => {
                    state.csrf = res.csrfToken;
                  });

                  runInInjectionContext(injector, () => inject(UserStore).status(isPlatformBrowser(platformId)));
                },
                error: err => {
                  if (isDevMode()) {
                    console.error('[ApplicationUiStore]', err);
                  }
                },
                finalize: () => {
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      switchLanguage: rxMethod<ISwitchLanguage>(
        pipe(
          switchMap((switchLanguage: ISwitchLanguage) => {
            const taskCleanup = pendingTasks.add();

            immerPatchState(store, state => {
              state.language = switchLanguage.language;
            });

            translocoService.setActiveLang(switchLanguage.language);
            renderer.setAttribute(ngDocument.documentElement, 'lang', switchLanguage.language);
            localizeRouterService.changeLanguage(switchLanguage.language);

            if (switchLanguage.save) {
              cookieService.setItem('translocoLang', switchLanguage.language);
            }

            taskCleanup();
            return of({});
          })
        )
      ),

      switchTheme: rxMethod<ISwitchTheme>(
        pipe(
          switchMap((switchTheme: ISwitchTheme) => {
            const taskCleanup = pendingTasks.add();

            immerPatchState(store, state => {
              state.theme = switchTheme.dark;
            });

            if (switchTheme.dark) {
              renderer.addClass(ngDocument.documentElement, 'dark');
              meta.updateTag({ name: 'theme-color', content: '#1E293B' });
            } else {
              renderer.removeClass(ngDocument.documentElement, 'dark');
              meta.updateTag({ name: 'theme-color', content: '#FFFFFF' });
            }

            const THEME_PREFERENCE_LOCAL_STORAGE_KEY = 'themePreferenceAE';
            localStorageService.set(THEME_PREFERENCE_LOCAL_STORAGE_KEY, switchTheme.dark ? 'dark' : 'light');

            // if (switchTheme.post) {
            //   return uiService.theme(switchTheme.dark);
            // }

            taskCleanup();
            return of({});
          })
        )
      ),
    };
  }),
  withHooks({
    onInit(store) {
      const router: Router = inject(Router);
      const seoTagService: SeoTagService = inject(SeoTagService);
      const jsonLdService: JsonLdService = inject(JsonLdService);
      const pendingTasks: PendingTasks = inject(PendingTasks);
      const cookieService: CookieService = inject(CookieService);
      const translocoService: TranslocoService = inject(TranslocoService);
      const ngDocument: Document = inject(DOCUMENT);
      const platformId: Object = inject(PLATFORM_ID);
      const rendererFactory: RendererFactory2 = inject(RendererFactory2);
      const renderer: Renderer2 = rendererFactory.createRenderer(null, null);
      const destroyRef: DestroyRef = inject(DestroyRef);
      const localStorageService: LocalStorageService = inject(LocalStorageService);

      let taskCleanupMain: () => void = pendingTasks.add();
      let taskCleanupInit: () => void = pendingTasks.add();
      let taskCleanup: (() => void) | null = null;

      router.events
        .pipe(
          takeUntilDestroyed(destroyRef),
          filter(event => event instanceof NavigationEnd),
          tap(() => {
            if (router.url.includes('signin') || router.url.includes('redirect')) {
              return;
            }

            if (store.redirectUrl()) {
              immerPatchState(store, state => {
                state.redirectUrl = null;
              });
            }
          }),
          tap(() => (taskCleanup = pendingTasks.add())),
          tap(() => {
            if (taskCleanupInit) {
              taskCleanupInit();
            }
          }),
          tap(() =>
            immerPatchState(store, state => {
              state.mainMenu = false;
            })
          ),
          map(() => {
            const url: string = router.url;
            let urlLang = 'en';

            if (url.startsWith('/nl')) {
              urlLang = 'nl';
            } else if (url.startsWith('/en')) {
              urlLang = 'en';
            }

            if (urlLang && store.language() !== urlLang) {
              store.switchLanguage({
                language: urlLang,
                save: true,
              });
            }

            return urlLang;
          }),
          tap(lang => {
            renderer.setAttribute(ngDocument.querySelector('#manifest'), 'href', `manifest.${lang}.webmanifest`);
          }),
          map(lang => {
            let route: ActivatedRoute = router.routerState.root;
            while (route!.firstChild) {
              route = route.firstChild;
            }

            immerPatchState(store, state => {
              state.hideLayout = route.snapshot.data['hideLayout'];
            });

            return {
              language: lang,
              titleParam: route.snapshot.data['titleParam']
                ? route.snapshot.params[route.snapshot.data['titleParam']]
                : null,
              localizedData: route.snapshot.data[<string>lang],
              params: route.snapshot.params,
            };
          }),
          tap(data => {
            if (!data.localizedData) {
              console.error('COULD NOT SET SEO TAGS!');
              return;
            }

            let title = data.localizedData.title;
            const breadcrumbs = data.localizedData.breadcrumbs;

            if (data.titleParam) {
              title += ` - ${titleCase(data.titleParam)}`;
            }

            seoTagService.setData({
              title: title,
              // keywords:
              //   data.language == 'en'
              //     ? 'archery, archery score, archery scoring, 3D archery, target shooting, club competition'
              //     : 'boog schieten, boogschieten, boogschiet score, 3D-boogschieten, schietwedstrijden, clubcompetitie',
              description:
                data.language == 'en' ? 'Archery Events members portal' : 'Archery Events members leden portaal',
              image:
                data.language == 'en'
                  ? 'https://archery-events.com/assets/img/enog.png'
                  : 'https://archery-events.com/assets/img/nlog.png',
              imageAuxData:
                data.language == 'en'
                  ? {
                      width: 1200,
                      height: 630,
                      secureUrl: 'https://archery-events.com/assets/img/enog.png',
                      mimeType: 'image/jpeg',
                      alt: 'Archery Events',
                    }
                  : {
                      width: 1200,
                      height: 630,
                      secureUrl: 'https://archery-events.com/assets/img/nlog.png',
                      mimeType: 'image/jpeg',
                      alt: 'Archery Events',
                    },
              url: {
                base: 'https://archery-events.com',
                url: router.url,
              },
              type: 'website',
              author: 'Owain van Brakel',
              section: 'Archery',
              twitterCard: 'summary_large_image',
            });

            if (breadcrumbs) {
              jsonLdService.clearData(data.language);
              jsonLdService.setBreadcrumbs(breadcrumbs, data.language, data.params);
              jsonLdService.inject();

              immerPatchState(store, state => {
                state.breadcrumbs = modifyBreadcrumbs(breadcrumbs, data.params);
              });
            } else if (isPlatformBrowser(platformId)) {
              jsonLdService.clearDataInject(data.language);

              immerPatchState(store, state => {
                state.breadcrumbs = [];
              });
            }
          }),
          tap(() => {
            if (taskCleanup) {
              taskCleanup();
            }
          })
        )
        .subscribe();

      if (isPlatformBrowser(platformId)) {
        store.generateCsrf();
      }

      const serverContext = getServerContext();

      if (serverContext === 'ssg') {
        if (taskCleanupMain) {
          taskCleanupMain();
        }

        return;
      }

      const language = cookieService.get('translocoLang');

      if (language && language !== '') {
        immerPatchState(store, state => {
          state.language = language;
        });
      }

      if (language) {
        translocoService.setActiveLang(language);
        renderer.setAttribute(ngDocument.documentElement, 'lang', language);
      } else {
        const url: string = router.url;
        let urlLang = 'en';

        if (url.startsWith('/nl')) {
          urlLang = 'nl';
        } else if (url.startsWith('/en')) {
          urlLang = 'en';
        }

        cookieService.setItem('translocoLang', urlLang);
      }

      if (isPlatformBrowser(platformId)) {
        const THEME_PREFERENCE_LOCAL_STORAGE_KEY = 'themePreferenceAE';
        const theme = localStorageService.get(THEME_PREFERENCE_LOCAL_STORAGE_KEY) ?? 'auto';

        immerPatchState(store, state => {
          state.theme = theme === 'dark';
        });
      }

      if (taskCleanupMain) {
        taskCleanupMain();
      }
    },
  })
);

function titleCase(input: string): string {
  return input
    .toLowerCase()
    .replaceAll('-', ' ')
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

function modifyBreadcrumbs(breadcrumbs: IBreadcrumbStore[], params: Params) {
  const newbreadcrumbs: IBreadcrumbStore[] = [];

  for (const [_, value] of breadcrumbs.entries()) {
    let breadcrumb: IBreadcrumbStore = {
      name: value.name,
    };

    if (value.name.includes(':')) {
      const nameParam = params[value['name'].replace(':', '')];

      if (nameParam) {
        breadcrumb.name = titleCase(nameParam);
      }
    }

    if (value.item) {
      breadcrumb = {
        ...breadcrumb,
        item: value.item,
      };

      if (value.item && value.item.includes(':')) {
        const split = value['item'].split('/');
        let newItem = '';

        for (const i of split) {
          if (i.includes(':')) {
            const itemParam = params[i.replace(':', '')];

            if (itemParam) {
              newItem += itemParam + '/';
            } else {
              newItem += i + '/';
            }
          } else {
            newItem += i + '/';
          }
        }

        breadcrumb.item = newItem;

        if (breadcrumb.item.endsWith('/')) {
          breadcrumb.item = breadcrumb.item.slice(0, -1);
        }
      }
    }

    newbreadcrumbs.push(breadcrumb);
  }

  return newbreadcrumbs;
}
