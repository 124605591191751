import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';
import { computed, inject, isDevMode, PendingTasks, PLATFORM_ID } from '@angular/core';
import type {
  IDisableTotp,
  IEnableTotp,
  INameUpdate,
  INewPassword,
  ISignIn,
  ISignInTotp,
  ISignUp,
  IUser,
  IUserState,
} from '@archery-events/models/user.model';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap } from 'rxjs';
import { tapResponse } from '@ngrx/operators';
import { AuthService } from '../../services/http/auth.service';
import { TranslocoService } from '@jsverse/transloco';
import { UserService } from '../../services/http/user.service';
import { Router } from '@angular/router';
import type { IMiscHttpError } from '@archery-events/models/misc.model';
import { isPlatformBrowser } from '@angular/common';
import { LocalizeRouterService } from '@owain/ngx-transloco-router/localize-router.service';
import { setLoaded, setLoading, withCallState } from '@owain/store-features/features/call-state/call-state.feature';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { EventBusService } from '@owain/eventbus/event-bus.service';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';
import { getServerContext } from '../../utils/rendering';
import { ApplicationUiStore } from '../application-ui/application-ui.store';
import { immerPatchState } from '@owain/store-features/state/immer.state';

export const initialState: IUserState = {
  isAuthenticated: false,
  user: null,
};

export const UserStore = signalStore(
  { providedIn: 'root' },
  // withLogger('UserStore'),
  withCallState(),
  // withIndexedDB('user-store-state', ['callstate']),
  withState(initialState),
  withMethods(store => {
    const authService: AuthService = inject(AuthService);
    const userService: UserService = inject(UserService);
    const toastService: ToasterService = inject(ToasterService);
    const translocoService: TranslocoService = inject(TranslocoService);
    const router: Router = inject(Router);
    const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
    const eventBusService: EventBusService = inject(EventBusService);
    const pendingTasks: PendingTasks = inject(PendingTasks);
    const applicationUiStore: InstanceType<typeof ApplicationUiStore> = inject(ApplicationUiStore);

    return {
      status: rxMethod<boolean>(
        pipe(
          switchMap((browser: boolean) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return authService.status(browser).pipe(
              tapResponse({
                next: (user: IUser | null): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = user != null;
                    state.user = user;
                  });

                  if (router.url === '/signin' || router.url.startsWith('/reset-password')) {
                    router.navigateByUrl(<string>localizeRouterService.translateRoute('/calendar'));
                  }
                },
                error: err => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      signIn: rxMethod<ISignUp>(
        pipe(
          switchMap((signIn: ISignIn) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return authService.signIn(signIn.email, signIn.password).pipe(
              tapResponse({
                next: (user: IUser): void => {
                  if (user.totp) {
                    eventBusService.cast('ui:needs-totp');
                  } else {
                    immerPatchState(store, state => {
                      state.isAuthenticated = true;
                      state.user = user;
                    });

                    toastService.notification({
                      alertType: NotificationType.SUCCESS,
                      message: translocoService.translate('signin.signedin'),
                    });

                    const redirectUrl = applicationUiStore.redirectUrl();

                    if (
                      !redirectUrl ||
                      redirectUrl.includes('40') ||
                      redirectUrl.includes('50') ||
                      redirectUrl.includes('redirect') ||
                      redirectUrl.includes('signin') ||
                      redirectUrl.includes('reset-password')
                    ) {
                      applicationUiStore.setRedirectUrl(null);
                      router.navigateByUrl(<string>localizeRouterService.translateRoute('/calendar'));
                    } else {
                      router.navigateByUrl(<string>localizeRouterService.translateRoute('/redirect'));
                    }
                  }
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`signin.wrongcredentials`),
                  });

                  eventBusService.cast('ui:clear-form');
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      signInTotp: rxMethod<ISignInTotp>(
        pipe(
          switchMap((signIn: ISignInTotp) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return authService.signInTotp(signIn.email, signIn.password, signIn.code).pipe(
              tapResponse({
                next: (user: IUser): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = true;
                    state.user = user;
                  });

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('signin.signedin'),
                  });

                  const redirectUrl = applicationUiStore.redirectUrl();

                  if (
                    !redirectUrl ||
                    redirectUrl.includes('40') ||
                    redirectUrl.includes('50') ||
                    redirectUrl.includes('redirect') ||
                    redirectUrl.includes('signin') ||
                    redirectUrl.includes('reset-password')
                  ) {
                    applicationUiStore.setRedirectUrl(null);
                    router.navigateByUrl(<string>localizeRouterService.translateRoute('/calendar'));
                  } else {
                    router.navigateByUrl(<string>localizeRouterService.translateRoute('/redirect'));
                  }
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`signin.wrongcredentialscode`),
                  });

                  eventBusService.cast('ui:clear-form');
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      signOut: rxMethod<void>(
        pipe(
          switchMap(() => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return authService.signOut().pipe(
              tapResponse({
                next: (): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = false;
                    state.user = null;
                  });

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('signin.signedout'),
                  });

                  router.navigateByUrl(<string>localizeRouterService.translateRoute('/'));
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      enableTotp: rxMethod<IEnableTotp>(
        pipe(
          switchMap((enableTotp: IEnableTotp) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return userService.enableTotp(enableTotp.secret, enableTotp.code).pipe(
              tapResponse({
                next: (): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = true;
                    // @ts-ignore
                    state.user = {
                      ...store.user(),
                      totp: true,
                    };
                  });

                  eventBusService.cast('ui:close-modal');

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('profile.enabledtfa'),
                  });
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`profile.${err.error.message}`),
                  });

                  eventBusService.cast('ui:clear-modal-form');
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      disableTotp: rxMethod<IDisableTotp>(
        pipe(
          switchMap((disableTotp: IDisableTotp) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return userService.disableTotp(disableTotp.code).pipe(
              tapResponse({
                next: (): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = true;
                    // @ts-ignore
                    state.user = {
                      ...store.user(),
                      totp: false,
                    };
                  });

                  eventBusService.cast('ui:close-modal');

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('profile.disabledtfa'),
                  });
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`profile.${err.error.message}`),
                  });
                  eventBusService.cast('ui:clear-modal-form');
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      fullNameUpdate: rxMethod<INameUpdate>(
        pipe(
          switchMap((name: INameUpdate) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return userService.fullnameUpdate(name).pipe(
              tapResponse({
                next: (): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = true;
                    // @ts-ignore
                    state.user = {
                      ...store.user(),
                      firstname: name.firstname,
                      insertion: name.insertion,
                      lastname: name.lastname,
                    };
                  });

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('profile.nameupdated'),
                  });
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`profile.${err.error.message}`),
                  });
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      emailUpdate: rxMethod<string>(
        pipe(
          switchMap((email: string) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return userService.emailUpdate(email).pipe(
              tapResponse({
                next: (): void => {
                  immerPatchState(store, state => {
                    state.isAuthenticated = true;
                    // @ts-ignore
                    state.user = {
                      ...store.user(),
                      email: email,
                    };
                  });

                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('profile.emailupdated'),
                  });
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`profile.${err.error.message}`),
                  });
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      passwordUpdate: rxMethod<string>(
        pipe(
          switchMap((password: string) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return userService.passwordUpdate(password).pipe(
              tapResponse({
                next: (): void => {
                  toastService.notification({
                    alertType: NotificationType.SUCCESS,
                    message: translocoService.translate('profile.passwordupdated'),
                  });

                  eventBusService.cast('ui:clear-form');
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  toastService.notification({
                    alertType: NotificationType.ERROR,
                    message: translocoService.translate(`profile.${err.error.message}`),
                  });
                  eventBusService.cast('ui:clear-form');
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      resetPasswordRequest: rxMethod<string>(
        pipe(
          switchMap((email: string) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return authService.resetPassword(email).pipe(
              tapResponse({
                next: (): void => {
                  toastService.notification({
                    alertType: NotificationType.INFO,
                    message: translocoService.translate('signin.passwordresetrequested'),
                  });

                  eventBusService.cast('ui:clear-form');
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),

      newPasswordReset: rxMethod<INewPassword>(
        pipe(
          switchMap((newPassword: INewPassword) => {
            const taskCleanup = pendingTasks.add();

            patchState(store, setLoading());

            return authService.newPassword(newPassword.token, newPassword.password).pipe(
              tapResponse({
                next: (): void => {
                  toastService.notification({
                    alertType: NotificationType.INFO,
                    message: translocoService.translate('signin.newpasswordreset'),
                  });

                  eventBusService.cast('ui:clear-form');

                  router.navigateByUrl(<string>localizeRouterService.translateRoute('/signin'));
                },
                error: (err: IMiscHttpError) => {
                  if (isDevMode()) {
                    console.error('[UserStore]', err);
                  }

                  if (err.status == 500) {
                    toastService.notification({
                      alertType: NotificationType.ERROR,
                      message: translocoService.translate('500error'),
                    });
                  } else if (err.status == 409) {
                    toastService.notification({
                      alertType: NotificationType.ERROR,
                      message: translocoService.translate(`signin.newpasswordreseterror`),
                    });
                  }

                  eventBusService.cast('ui:clear-form');
                },
                finalize: () => {
                  patchState(store, setLoaded());
                  taskCleanup();
                },
              })
            );
          })
        )
      ),
    };
  }),
  withHooks({
    onInit({ status }) {
      const serverContext = getServerContext();

      if (serverContext === 'ssg') {
        return;
      }

      const platformId: Object = inject(PLATFORM_ID);

      if (!isPlatformBrowser(platformId)) {
        status(isPlatformBrowser(platformId));
      }
    },
  }),
  withComputed(({ isAuthenticated, user }) => ({
    authenticated: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      return authenticatedState && userState != null;
    }),

    userId: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return -1;
      }

      return userState.id;
    }),

    userEmail: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return '';
      }

      return userState.email;
    }),

    userFullname: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return '';
      }

      if (userState.insertion) {
        return `${userState.firstname} ${userState.insertion} ${userState.lastname}`;
      }

      return `${userState.firstname} ${userState.lastname}`;
    }),

    userFirstname: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return '';
      }

      return userState.firstname;
    }),

    userInsertion: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return '';
      }

      return userState.insertion;
    }),

    userLastname: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return '';
      }

      return userState.lastname;
    }),

    totpEnabled: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      if (!authenticatedState || !userState) {
        return false;
      }

      return userState.totp;
    }),

    isAdmin: computed(() => {
      const authenticatedState = isAuthenticated();
      const userState = user();

      return authenticatedState && userState != null && userState.role === 'ADMIN';
    }),
  }))
);
